import {
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import useRoles from "./useRoles";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import CreateRoleModal from "./CreateRoleModal";
import { Pagination } from "../../Global/Pagination";

const Roles = () => {
  const {
    roleList,
    roleState,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    handleCreateClick,
    handleClearClick,
    updateRoleFields,
    handlecloseModal,
    handlePagination,
    handleEditAction,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useRoles();
  return (
    <div>
      <HeadingGroup
        title={"All Roles"}
        className={`pro-mb-4`}
        buttonTitle={roleList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={roleState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: roleState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {roleList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            extraClasssName={`table-wrap`}
            multiSelect={false}
            data={roleList?.data?.data || []}
            uniqueID={"id"}
            fields={roleList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={roleState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            deletable={false}
            editable={roleList?.data?.permissions?.can_update}
            showCheckBox={roleList?.data?.permissions?.can_update}
          />
        )}
        {roleList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={roleState?.currentPage}
            totalPageCount={Math.ceil(
              roleList.data.total_count / roleState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={roleList?.data?.fields || []}
              moduleId={roleList?.data?.table_id}
              updateData={updateRoleFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          centered={false}
          show={roleState?.addModal}
          handleClose={handlecloseModal}
          title={"Create Role"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <CreateRoleModal />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Roles;
