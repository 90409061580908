import React from "react";
import EmptyData from "../../Global/EmptyData";
import DashboardHero from "../DashboardHero";
import Style from "./dashboardListing.module.scss";
import useDashboardHome from "./useDashboardHome";
import {
  HeadingGroup,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";

const DashboardHome = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    listingType,
    initialDate,
    tableFields,
    dashboardState,
    getRow,
    handleDateChange,
    handleSort,
  } = useDashboardHome();
  return (
    <div className={Style.dashboard_wrap}>
      <div className="row ">
        <div className="col ">
          <HeadingGroup
            title={"Dashboard"}
            extraClassName={`pro-mb-0 heading-wrap`}
          />
        </div>
        <div className="col-auto">
          <SearchFilters
            // onSearchInput={handleSearch}
            searchable={false}
            showActions={false}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          // data={dashboardData?.data}
          data={dashboardData?.data?.count}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
          shimmerCount={16}
        />
      </div>
      {dashboardData?.data?.data && Object.keys(dashboardData?.data?.data)?.length !== 0 ? (
        <div className={`pro-pt-3 ${Style.tbl_col_title}`}>
          <Table
            extraClasssName={`table-wrap`}
            multiSelect={false}
            data={dashboardData?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={dashboardState?.currentPageSize}
            editable={false}
            deletable={false}
            assignable={false}
            showCheckBox={false}
          />
        </div>
      ) : (
        <>
          {!isFetching ? (
            <EmptyData />
          ) : (
            <div className="app-loading fill"></div>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardHome;
