import {
  OffCanvasLayout,
  Table,
  ModalLayout,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import useUserDetails from "./useUserDetails";
import Style from "./userDetails.module.scss";
import SocialContainer from "../../Global/SocialContainer";
import Link from "../Link";
import SocialConnectForm from "./SocialConnectForm";
import { Pagination } from "../../Global/Pagination";
const UserDetails = () => {
  const {
    isFetching,
    usersState,
    headerData,
    createLimit,
    tableFields,
    headerDetails,
    headerFetching,
    showDeleteModal,
    socialMediaStatus,
    paginationOptions,
    hasViewPermission,
    userCollabDataList,
    hasCreatePermission,
    hasRemovePermission,
    hasUpdatePermission,
    isSocialConnectionEdit,
    getRow,
    refetch,
    handleSort,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleExportTable,
    handleDeleteClick,
    handleDeleteAction,
    handleCreateClick,
    handleLinkCloseClick,
    handleCloseCreateModal,
    statusFetching
  } = useUserDetails();



  return (
    <div className="pro-ps-5 pro-pt-5">
      <div className="row g-3">
        <div className="col-12">
          {hasViewPermission && (
            <SocialContainer
              isLoading={headerFetching}
              headerDetails={headerDetails}
              headerData={headerData}
              handleEditAction={handleEditAction}
              handleDeleteClick={handleDeleteClick}
              hasRemovePermission={hasRemovePermission}
              hasUpdatePermission={hasUpdatePermission}
              socialMediaStatus={socialMediaStatus}
              createMedia={createLimit?.length > 0}
              handleCreateClick={hasCreatePermission && handleCreateClick}
            />
          )}
        </div>
      </div>
      <div className="pro-pt-5">
        {userCollabDataList?.data?.data?.length > 0 ? (
          <div>
            <div className="pro-d-flex pro-justify-between pro-items-center">
              <h6 className="pro-ttl pro-mb-0 h6">Collaboration</h6>
              {userCollabDataList?.data?.permissions?.can_export && (
                <button
                  className={"pro-btn pro-items-center pro-btn-outline "}
                  onClick={handleExportTable}
                >
                  <span className="material-symbols-outlined x4">
                    outgoing_mail
                  </span>
                  <span>Export Table</span>
                </button>
              )}
            </div>
            <div className="pro-pb-5">
            <Table
              multiSelect={false}
              data={userCollabDataList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={usersState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={usersState?.user?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={false}
              extraClasssName={`table-wrap`}
            />
            {userCollabDataList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={usersState?.user?.currentPage}
                totalPageCount={Math.ceil(
                  userCollabDataList?.data?.total_count /
                    usersState.user?.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
            </div>
          </div>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={usersState?.linkModal}
          handleClose={handleLinkCloseClick}
          title={"Links"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          className={Style.offcanvasLink}
        >
          <Link />
        </OffCanvasLayout>

        <OffCanvasLayout
          show={usersState?.showCreateModal}
          handleClose={handleCloseCreateModal}
          // title={usersState?.isEdit ? "Update User" : "Create User"}
          title={
            isSocialConnectionEdit
              ? "Update Social Media "
              : "Connect Social Media"
          }
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          className={Style.offcanvasCreate}
        >
          <SocialConnectForm refetch={refetch} headerData={headerData} statusFetching={statusFetching} />
        </OffCanvasLayout>
      </div>

      <ModalLayout show={showDeleteModal} handleClose={handleDeleteClick}>
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Are you sure delete this connection ?"}
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleDeleteClick}
            submitText={"Yes"}
            submitAction={handleDeleteAction}
            isRight={true}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default UserDetails;
