import {
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useInterests from "./useInterests";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import AddInterest from "./AddInterest";
import { Pagination } from "../../Global/Pagination";

const Interests = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    interestsList,
    interestsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
  } = useInterests();

  return (
    <div>
      <HeadingGroup
        title={"Interests"}
        className={`pro-mb-4`}
        buttonTitle={interestsList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: interestsState?.search }}
        />
        {interestsList?.data?.data?.length > 0 ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={interestsList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={interestsState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={interestsState?.currentPageSize}
              editable={interestsList?.data?.permissions?.can_update}
              deletable={false}
              assignable={false}
              showCheckBox={interestsList?.data?.permissions?.can_update}
            />
            {interestsList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={interestsState?.currentPage}
                totalPageCount={Math.ceil(
                  interestsList?.data?.total_count /
                    interestsState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout
          show={interestsState?.createModal}
          handleClose={handleCloseClick}
          title={interestsState?.isEdit ? "Edit Interest" : "Add Interest"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <AddInterest refetch={refetch} />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Interests;
