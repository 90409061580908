import {
    ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import { Pagination } from "../../Global/Pagination";
import useTemplate from "./useTemplate";
import AddTemplate from "./AddTemplate";

const Template = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    templateList,
    templateState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteAction,
  } = useTemplate();

  return (
    <div>
      <HeadingGroup
        title={"Template"}
        className={`pro-mb-4`}
        buttonTitle={templateList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: templateState?.search }}
        />
        {templateList?.data?.data?.length > 0 ? (
          <>
            <Table
              extraClasssName={`table-wrap`}
              multiSelect={false}
              data={templateList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleEdit={handleEditAction}
              handleDelete={handleDeleteModal}
              handleSort={handleSort}
              clear={templateState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={templateState?.currentPageSize}
              editable={templateList?.data?.permissions?.can_update}
              deletable={templateList?.data?.permissions?.can_delete}
              assignable={false}
              showCheckBox={true}
            />
            {templateList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={templateState?.currentPage}
                totalPageCount={Math.ceil(
                  templateList?.data?.total_count /
                    templateState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout
          show={templateState?.createModal}
          handleClose={handleCloseClick}
          title={templateState?.isEdit ? "Edit Template" : "Add Template"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <AddTemplate refetch={refetch} />
          </div>
        </ModalLayout>
        <ModalLayout
          show={templateState?.deleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Template;
